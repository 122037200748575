import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { format as formatDate } from "date-fns";
import { de, enUS } from "date-fns/locale";
import { fetchOrders, resetActiveOrder, resetConfiguration } from '../../redux/actions';
import { getActiveOrder, getUser } from '../../redux/reducers';
import Floorball from '../../components/Floorball/Floorball';
import { FLOORBALL_COLOR_EMPTY_RED } from '../../ressources/types/floorball.types';
import './orderConfirmation.scss';

function OrderConfirmation({ order, user, resetActiveOrder, resetConfiguration }: PropsFromRedux) {
    const { t } = useTranslation();
    const [leftColor, setLeftColor] = useState<string>(FLOORBALL_COLOR_EMPTY_RED);
    const [rightColor, setRightColor] = useState<string>(FLOORBALL_COLOR_EMPTY_RED);
    const [orderId, setOrderId] = useState<string>("");
    const [date, setDate] = useState<string>('--.--.----, --:--');
    const navigate = useNavigate();

    useEffect(() => {
        if (order) {
            setLeftColor(order.floorball.leftHalf.color.name);
            setRightColor(order.floorball.rightHalf.color.name);
            setOrderId(order.salesDocumentId);

            let locale = de;
            switch (i18n.language) {
                case "en":
                    locale = enUS;
                    break;
            }

            const date = formatDate(new Date(), "Pp", {locale})
            setDate(date); // TODO: Check dependencies
        }
        if (user == null || !user.isGuest) {
            resetActiveOrder();
        }
        return () => {
            resetConfiguration();
        };
    }, [order]);

    const redirect = (target: string) => {
        navigate(target);
    };

    return (
        <div id='order-confirmation'>
            <h1>{t('pages-orderConfirmation-title')}</h1>
            <div className='order-summary'>
                <div className='confirmation-floorball'>
                    <Floorball leftColor={leftColor} rightColor={rightColor} />
                </div>
                <div className='order-description'>
                    <h2>
                        {t('pages-orderConfirmation-subtitle')} {orderId}
                    </h2>
                    <h3>
                        {t('pages-orderConfirmation-subsubtitle1')} {date}
                    </h3>
                    <p>{t('pages-orderConfirmation-description')}</p>
                    <button onClick={() => redirect('/orders')}>{t('button-viewOrders')}</button>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    order: getActiveOrder(state),
    user: getUser(state),
});

const mapDispatchToProps = {
    resetActiveOrder,
    resetConfiguration,
    fetchOrders,
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(OrderConfirmation);
