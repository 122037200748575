/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

export type loginResponseType = Response & {
    user: Customer;
};

export type OrderResponse = {
    orders: Order[]
}

export type Order = {
    salesDocumentId: string;
    referencedSerialNumber: string;
    floorball: Floorball;
    createdAt: string;
    state: number;
};

export type Customer = {
    customerId: number;
    firstname: string | null;
    lastname: string | null;
    email: string | null;
    debitorNumber: number;
};

export type Floorball = {
    materialNumber: string;
    leftHalf: FloorballComponent
    rightHalf: FloorballComponent
    productionData: WeldingData | null;
};

export type WeldingData = {
    temperature: number;
    temperature_qc: number;
    humidity_qc: number;
    humidity: number;
    serialNo: string;
    changeoverTime: number;
    changeoverTime_qc: number;
    serialNo_qc: number;
    heatingTime: number;
    weldingTime_qc: number;
    weldingTemperature_qc: number;
    serialNo_BallHalf2_qc: number;
    serialNo_BallHalf1_qc: number;
    serialNo_BallHalf2: string;
    weldingTemperature: number;
    serialNo_BallHalf1: string;
    weldingTime: number;
    heatingTime_qc: number;
    _time: string;
};

export type FloorballComponent = {
    productionData: ProductionData | null;
    materialNumber: string;
    description: string;
    color: ColorType;
};

export type ProductionData = {
    serialNo: string;
    color_qc: number;
    serialNo_qc: number;
    color: string;
    _time: string;
    machineData: MachineData;
    environmentData: EnvironmentData;
};

export type MachineData = {
    holdingPressure_qc: number;
    temperature_Nozzle: number;
    injectionTime_qc: number;
    holdingPressureTime_qc: number;
    cycleTime_qc: number;
    temperature_Cylinder_Z2_qc: number;
    temperature_Flange_qc: number;
    temperature_TemperingDevice_DS_qc: number;
    injectionTime: number;
    temperature_Nozzle_qc: number;
    temperature_Cylinder_Z1_qc: number;
    holdingPressure: number;
    cycleTime: number;
    temperature_Cylinder_Z2: number;
    temperature_Cylinder_Z1: number;
    injectionPressure_Max_qc: number;
    holdingPressureTime: number;
    temperature_TemperingDevice_AS_qc: number;
    meltCushion_qc: number;
    temperature_Flange: number;
    injectionPressure_Max: number;
    temperature_TemperingDevice_AS: number;
    meltCushion: number;
    _time: string;
    temperature_TemperingDevice_DS: number;
};

export type EnvironmentData = {
    temperature: number;
    temperature_qc: number;
    humidity_qc: number;
    humidity: number;
    _time: string;
};

export type ColorType = {
    hexCode: string;
    name: string;
    germanName: string | null;
};

export type StocksResponse = {
    stocks: StockType[]
}

export type StockType = {
    materialNumber: string;
    description: string;
    color: ColorType;
    amount: number;
    minimumNeededInStock: number;
};

export type Configuration = {
    lazyLoader?: any,
    id?: number,
    name: string,
    manualActivated: boolean,
    source: string,
    factoryAvailable?: boolean,
    reason: string | null,
    changedAt?: string
    enabled?: boolean
}

export type Status = {
    isSystemAvailable: boolean,
    statusMessage: string
}

export const FLOORBALL_COLOR_EMPTY_RED = 'empty_red';

export enum FloorballColor {
    BLACKBERRY = 'blackberry',
    RASPBERRY = 'raspberry',
    ORANGE = 'orange',
    BLUE = 'blue',
    GREEN = 'green',
    YELLOW = 'yellow',
    GRAY = 'gray',
    WHITE = 'white',
    BLACK = 'black',
    EMPTY_RED = 'empty_red',
    EMPTY_WHITE = 'empty_white',
}

export enum FloorballColorHex {
    BLACKBERRY = '#8c195f',
    RASPBERRY = '#d72864',
    ORANGE = '#c22e14',
    BLUE = '#0072af',
    GREEN = '#007d6a',
    YELLOW = '#fbb900',
    BLACK = '#191919',
    GRAY = '#c6c6c5',
    WHITE = '#ffffff',
}

export enum FloorballState {
    PRODUCTION,
    READY,
    CLOSED ,
    QUEUE
}
