import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import {
    getActiveOrderIsLoading,
    getAppConfig,
    getAppConfigIsLoading,
    getConfigurationLeftColor,
    getConfigurationRightColor, getUser,
} from '../../redux/reducers';
import './orderNavigator.scss';
import { createOrder, fetchAppConfig } from '../../redux/actions';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';

function OrderNavigator({
                            user,
                            leftColor,
                            rightColor,
                            isLoadingOrder,
                            isLoadingAppConfig,
                            appConfig,
                            createOrder,
                        }: PropsFromRedux) {
    const { t } = useTranslation();
    const [orderDisabled, setOrderDisabled] = useState<boolean>(false);
    const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);

    const handleSubmit = () => {
        setConfirmationOpen(true);
    };

    function confirmationAction() {
        const floorball =  { leftHalf : leftColor, rightHalf : rightColor }
        createOrder(floorball);
    }

    useEffect(() => {
        if (appConfig) {
            setOrderDisabled(!appConfig.isSystemAvailable);
        }
    }, [appConfig, isLoadingAppConfig, orderDisabled]);

    function isLoggedIn() {
        return user != null;
    }

    function hasChosenColors() {
        return leftColor.color != null && rightColor.color != null;
    }

    function isButtonDisabled() {
        return !isLoggedIn() || !hasChosenColors() || isLoadingOrder;
    }

    function getButtonLabel() {
        if (!isLoggedIn()) {
            return t('pages.configurator.orderButton.pleaseLogIn');
        }
        if (!hasChosenColors()) {
            return t('pages.configurator.orderButton.selectColors');
        }
        return t('pages.configurator.orderButton.orderFloorball');
    }

    return (
        <div id='order-navigator'>
            <ConfirmationModal
                open={confirmationOpen}
                setOpen={setConfirmationOpen}
                action={() => confirmationAction()}
                title={t('orderNavigatorBtn-confirmationModal-title')}
                description={t('orderNavigatorBtn-confirmationModal-description')}
            />
            <Button loading={isLoadingOrder || isLoadingAppConfig} disabled={isButtonDisabled()}
                    onClick={handleSubmit}>
                {getButtonLabel()}
            </Button>
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    leftColor: getConfigurationLeftColor(state),
    rightColor: getConfigurationRightColor(state),
    isLoadingOrder: getActiveOrderIsLoading(state),
    isLoadingAppConfig: getAppConfigIsLoading(state),
    appConfig: getAppConfig(state),
    user: getUser(state),
});

const mapDispatchToProps = { createOrder, fetchAppConfig };

type PropsFromRedux = ConnectedProps<typeof connector>;

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(OrderNavigator);
