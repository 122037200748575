import React, { Suspense } from 'react';
import {HashRouter, Route, Routes} from 'react-router-dom';

import Home from './pages/Home/Home';
import Configurator from './pages/Configurator/Configurator';
import Orders from './pages/Orders/Orders';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import ProductionData from './pages/ProductionData/ProductionData';
import BackButton from './components/BackButton/BackButton';
import Login from './pages/Login/Login';
import ErrorModal from './components/ErrorModal/ErrorModal';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import GuestLogin from './pages/GuestLogin/GuestLogin';

function App() {
    return (
        <HashRouter>
            <Suspense fallback='loading'>
                <Header />
                <section id='content'>
                    <BackButton />
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='/login' element={<Login />} />
                        <Route path='/configurator' element={<Configurator />} />
                        <Route path='/orders' element={<Orders />} />
                        <Route path='/production-data' element={<ProductionData />} />
                        <Route path='/guest-login' element={<GuestLogin />} />
                        <Route element={<PageNotFound />} />
                    </Routes>
                </section>
                <Footer />
                <ErrorModal />
            </Suspense>
        </HashRouter>
    );
}

export default App;
