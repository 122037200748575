import { AxiosResponse } from 'axios';
import axiosInstance from '../utils/axiosInstance';
import {
    Configuration,
    Floorball,
    Order,
    OrderResponse,
    Status,
    StocksResponse,
} from '../ressources/types/floorball.types';
import { AuthResult, User } from '../ressources/types/auth.types';

function postJson(endpoint: string, params: any): Promise<any> {
    return axiosInstance
        .post(`${endpoint}`, JSON.stringify(params), {
            headers: { 'Content-Type': 'application/json' },
        }).then((res) => {
            return checkRefresh(res, endpoint, params);
        });
}

function getJson(endpoint: string): Promise<any> {
    return axiosInstance.get(`${endpoint}`).then((res) => {
        if (res.statusText === 'refreshed') {
            return axiosInstance.post(`${endpoint}`);
        }
        return res;
    });
}

function putJson(endpoint: string, params: any): Promise<any> {
    return axiosInstance.put(`${endpoint}`, JSON.stringify(params), {
        headers: { 'Content-Type': 'application/json' },
    }).then((res) => {
        return checkRefresh(res, endpoint, params);
    });
}

function checkRefresh(res: AxiosResponse, endpoint: string, params: any) {
    if (res.statusText === 'refreshed') {
        return axiosInstance.post(`${endpoint}`, JSON.stringify(params), {
            headers: { 'Content-Type': 'application/json' },
        });
    }
    return res;
}

export function login(username: string, password: string): Promise<AxiosResponse<AuthResult>> {
    return postJson('/auth/login', {
        username,
        password,
    });
}

export function register(firstname: string, lastname: string, email: string, password: string): Promise<AxiosResponse> {
    return postJson('/auth/register', {
        firstname,
        lastname,
        email,
        password,
    });
}

export function loginAsGuest(token: string): Promise<AxiosResponse> {
    return postJson('/auth/guestlogin', token);
}

export function signout(): Promise<AxiosResponse> {
    return postJson('/auth/logout', {});
}

export function getUser(): Promise<User> {
    return getJson('/auth/users');
}

export function getProduct(id: string): Promise<AxiosResponse> {
    return getJson(`/products/${id}`);
}

export function getOrders(): Promise<AxiosResponse<OrderResponse>> {
    return getJson(`/orders`);
}

export function postOrder(floorball: any): Promise<AxiosResponse<Order>> {
    return postJson(`/orders`, floorball);
}

export function getOrder(id: string): Promise<AxiosResponse> {
    return getJson(`/orders/${id}`);
}

export function getStocks(): Promise<AxiosResponse<StocksResponse>> {
    return getJson('/stocks');
}

export function getFloorballData(serialNumber: number): Promise<AxiosResponse<Floorball>> {
    return getJson(`/products/floorball?ballhalfId=${serialNumber}`);
}

export function getAppConfig(): Promise<AxiosResponse<Status>> {
    return getJson(`/status`);
}

export function putAppConfig(config: Configuration): Promise<AxiosResponse<Configuration>> {
    return putJson('/configuration/orderservice', config);
}
