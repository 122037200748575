import axios from 'axios';
import jwtDecode from 'jwt-decode';
import dayjs from 'dayjs';
import { JWT } from '../ressources/types/auth.types';
import EnvVariableHelper from './envVariableHelper';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'regenerator-runtime/runtime';
import constants from '../ressources/constants';

const baseURL = EnvVariableHelper.VARIABLES.REACT_APP_BACKEND_URL;

let accessToken: string | null = localStorage.getItem(constants.ACCES_STOKEN);
let refreshToken: string | null = localStorage.getItem(constants.REFRESH_STOKEN);

const axiosInstance = axios.create({
    baseURL,
});

axiosInstance.interceptors.request.use(async (req) => {
    accessToken = localStorage.getItem(constants.ACCES_STOKEN);
    refreshToken = localStorage.getItem(constants.REFRESH_STOKEN);
    if (req?.headers && accessToken) {   
        req.headers.Authorization = `Bearer ${accessToken}`;
    }
    if(accessToken && refreshToken) {
        const user: JWT = jwtDecode(accessToken);
        const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
        if (!isExpired) {
            return req;
        }
        const response = await renewAccessToken();
        localStorage.setItem(constants.ACCES_STOKEN, response.data.accessToken);
        if (req?.headers) {
            req.headers.Authorization = `Bearer ${response.data.accessToken}`;
        }
    }
    return req;
});

axiosInstance.interceptors.response.use(async (res) => {
    return res;
}, async (err) => {
    if(err.response?.status === 401 && refreshToken) {
        const refreshRes = await renewAccessToken();
        if(refreshRes.status === 200) {            
            localStorage.setItem(constants.ACCES_STOKEN, refreshRes.data.accessToken);
            refreshRes.statusText = 'refreshed';
            return refreshRes;
        } 
        localStorage.removeItem(constants.ACCES_STOKEN);
        localStorage.removeItem(constants.REFRESH_STOKEN);
        
    } 
    throw err;
});

function renewAccessToken() {
    return axios.post(`${baseURL}/auth/refresh/`, {}, {
        headers: {
            Authorization: `Bearer ${refreshToken}`
        }
    });
}

export default axiosInstance;
